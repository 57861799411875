import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useHistory } from "react-router";

const ACTIONS: any = {
  email: "Email",
  http: "Http request",
  stock: "Stock price",
  crypto: "Crypto price",
  currency: "Currency exchange",
  time: "World time",
  quote: "Inspirational quote",
};

const ActionTypesModal: React.FC = () => {
  const history = useHistory();
  const onDismiss = () => history.replace("/dashboard");
  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle>Type</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {Object.keys(ACTIONS).map((action) => (
            <IonItem key={action} button routerLink={`/dashboard/${action}`}>
              <IonLabel>{ACTIONS[action]}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ActionTypesModal;
