import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  ScrollDetail,
} from "@ionic/react";
import { chevronDownOutline, heart } from "ionicons/icons";
import { useState } from "react";

const Home: React.FC = () => {
  const [showDownIndicator, setShowDownIndicator] = useState(true);

  const handleOnScroll = (ev: CustomEvent<ScrollDetail>) => {
    if (showDownIndicator && ev.detail.scrollTop > 10) {
      setShowDownIndicator(false);
    }
    if (!showDownIndicator && ev.detail.scrollTop < 10) {
      setShowDownIndicator(true);
    }
  };

  return (
    <IonPage>
      <IonContent scrollEvents onIonScroll={handleOnScroll} fullscreen>
        <div className="px-4 pb-16 max-w-xl m-auto flex flex-col gap-8">
          <div className="h-screen flex flex-col justify-center items-center">
            <img
              className="w-24 h-24 rotate linear infinite"
              style={{ transition: "all ease 0.3s" }}
              src="/assets/icon/icon-outline.png"
              alt="logo"
            />
            <h1 className="mt-8 text-4xl">Garminfy</h1>
            <div className="my-4 text-2xl text-center">
              Enhance your Garmin experience
            </div>
            <IonButton className="mt-8" shape="round" routerLink="/dashboard">
              Continue
            </IonButton>
            <a
              href="https://apps.garmin.com/en-US/apps/242c4e9b-8a21-4d72-8557-a0c14e633ba1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-48 mt-8 mx-auto"
                style={{ transition: "all ease 0.3s" }}
                src="/assets/images/garmin-connect-download.png"
                alt="logo"
              />
            </a>
            {showDownIndicator && (
              <IonIcon
                className="bounce fixed bottom-8"
                size="large"
                icon={chevronDownOutline}
              />
            )}
          </div>
          <div className="text-2xl">
            <p>
              <b>Garminfy</b> is a powerful Garmin Widget that enables you to
              execute a vast array of tasks directly from your device. With
              Garminfy, you can perform a multitude of actions, such as sending
              emails, making HTTP requests, checking stock and crypto
              prices, monitoring currency exchanges, and getting the time of any
              region of the world. Additionally, Garminfy provides you with
              inspirational quotes to keep you motivated and on track throughout
              the day.
            </p>
            <br />
            <p>
              Whether you're a professional athlete, a weekend warrior, or
              someone who simply wants to maximize your Garmin device, Garminfy
              is the perfect solution for you. It's easy to use, simple to
              install, and packed with features that will help you optimize your
              productivity and stay on top of your tasks. With its intuitive
              interface and comprehensive capabilities, Garminfy is a must-have
              tool for anyone who wants to take their productivity to the next
              level.
            </p>
            <br />
            <p>
              Give Garminfy a try today and experience the difference for
              yourself!
            </p>
          </div>
          <div>
            <a
              href="https://apps.garmin.com/en-US/apps/242c4e9b-8a21-4d72-8557-a0c14e633ba1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-72 mt-8 mx-auto"
                style={{ transition: "all ease 0.3s" }}
                src="/assets/images/garmin-connect-download.png"
                alt="logo"
              />
            </a>
          </div>
          <div className="text-center pt-16">
            <h2>
              Made with <IonIcon className="mb-[-4px]" icon={heart} /> by{" "}
              <a
                className="font-bold"
                href="https://kpots.com"
                target="_blank"
                rel="noreferrer"
              >
                Kpots
              </a>
            </h2>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
